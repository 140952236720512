import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container,  ContentWithPaddingXlA } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
// import Header from "components/headers/light.js";


const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Formation Devops" }) => {
  return (
    <AnimationRevealPage>
     
      <Container>
        <ContentWithPaddingXlA>
          
          <Text>
            <p>Application web et mobile pour la validation et la géolocalisation des adresses physiques du contribuable. La mise à jour des informations du contribuable se fait en croisant les données de la DGID avec celles des fournisseurs d’eau et d’électricité.</p>

            <h1>LES FONCTIONNALITÉS PRINCIPALES</h1>
            
            <ul>
              <li>
                <p>
                  Croisement des données 
                </p>
              </li>
              <li>
                <p>
                  Vérification en temps réel de l’adresse du contribuable
                </p>
              </li>
              <li>
                Exposition des données d’adressage des contribuables aux applications du SI

              </li>
              <li>
                Gestion de la distribution du courrier

              </li>
              <li>
                Transmission automatique des accuses de réception par le coursier à travers l’application mobile

              </li>{" "}
            </ul>  

          </Text>
        </ContentWithPaddingXlA>
      </Container>
     
    </AnimationRevealPage>
  );
};
