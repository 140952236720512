import React, { useEffect, useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const VideoContainer = styled.div`
  ${tw`relative my-10 mx-auto bg-black rounded-lg shadow-lg`}; /* Retirer le padding */
  padding: 0.5rem; /* Ajouter un padding personnalisé */
  max-width: 800px; /* Réduisez la largeur maximale du conteneur */
  text-align: center; /* Centre le contenu horizontalement */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Ombre sur les contours */
`;


const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  ${tw`w-full`}
  position: relative;
  padding-bottom: 56.25%; /* Ratio d'aspect 16:9 */
  height: 0; /* Hauteur zéro pour que le padding-bottom prenne le relais */

  iframe {
    ${tw`rounded-lg shadow-xl`};
    position: absolute; /* Positionnez l'iframe */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Rendre l'iframe responsive */
  }
`;

export default () => {
  const videoRef = useRef(null);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const iframe = videoRef.current.querySelector('iframe');
          if (iframe) {
            // Envoyer le message pour jouer la vidéo
            iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
          }
        }
      },
      { threshold: 0.5 } // Déclenche l'événement lorsque 50 % de la vidéo est visible
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <VideoContainer ref={videoRef}>
      <StyledResponsiveVideoEmbed
        url="https://www.youtube.com/embed/YTbFFyMv3V8?enablejsapi=1&autoplay=0&rel=0"
        title="YouTube video player"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      />
    </VideoContainer>
  );
};
