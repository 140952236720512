import React, { useState } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { SectionHeading } from "components/misc/Headings.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeatures from "components/features/TwoCol.js";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import MainFeature3 from "components/features/TwoColSingleFeatureWithStats3.js";
import MainFeature4 from "components/features/TwoColSingleFeatureWithStats4.js";
import styled from "styled-components";

export default () => {
  const [setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };

  const Heading = tw(SectionHeading)`w-full`;
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const SectionDescriptions = tw(DescriptionBase)`text-center mx-auto text-gray-600 max-w-4xl`;
  const HighlightedText = tw.span`bg-pink-700 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-4xl`;

  // Nouveau style pour le titre "Gestion du Recouvrement"
  const RecoveryHeading = styled.h2`
    ${tw`text-5xl font-extrabold mt-0`}
    color: #243E63; // Change la couleur ici
  `;

  // Nouveau composant pour la description uniforme
  const Description = styled.span`
    ${tw`inline-block mt-8 text-lg text-gray-600`}
    p {
      ${tw`mt-2 leading-loose`}
    }
      ul {
    ${tw`list-disc pl-5 inline-block text-left`} /* Updated to list-disc for standard bullets */
    li {
      ${tw`mb-5`}
      p {
        ${tw`ml-3 text-base`}
      }
  `;

  return (
    <AnimationRevealPage>
      <Heading>Notre travail en <HighlightedText><span tw="text-pink-700">Vedette</span></HighlightedText></Heading>
      <SectionDescriptions>
      Que vous ayez besoin de développement web, d’applications mobiles, d'autres solutions logicielles, de cybersécurité, d’audit, de conseil ou de formation, notre équipe d’experts possède les compétences nécessaires pour transformer votre vision en un projet unique et réussi. Nous nous engageons à fournir des solutions sur mesure qui répondent à vos besoins spécifiques, tout en assurant la sécurité et l’efficacité de vos systèmes.
      </SectionDescriptions>
      <MainFeatures
        subheading={<Subheading> </Subheading>}
        heading={
          <>
            Gestion Electronique des
            <wbr /> <HighlightedText>Documents</HighlightedText>
          </>
        }
        description={
          <Description>
            La GED (Gestion Électronique de Documents) est un système numérique conçu pour gérer, stocker et organiser les documents électroniques de manière efficace et sécurisée. 
            <div>
              <ul>
                <li><p>Plan de classement</p></li>
                <li><p>Moteur de workflows de transmission et validation</p></li>
                <li><p>Collaboration sur des documents</p></li>
                <li><p>Génération de QR code sur les documents valides en PDF/A</p></li>
                <li><p>Gestion du versioning</p></li>
                <li><p>Signature électronique et Authentification</p></li>
                <li><p>Traçabilité</p></li>
                
              </ul>
            </div>
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        onClick={onLoadMoreClick}
        primaryButtonText="Voir plus"
        primaryButtonUrl="/ged"
        imageSrc={"/images/ged.png"}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />

      <MainFeature3
        subheading={<Subheading></Subheading>}
        heading={<>Idyal <HighlightedText>Drive</HighlightedText></>}
        description={
          <Description>
            Idyal Drive est une solution de stockage, virtuelle, personnalisée et privée.
            Elle est composée de trois environnements clients (Web, Mobile Android.) et un environnement management (Backoffice).
            <div>
              <ul>
                <li><p>Photos</p></li>
                <li><p>Vidéos</p></li>
                <li><p>Audios</p></li>
                <li><p>Contacts</p></li>
                <li><p>Journal d’appel</p></li>
                <li><p>SMS</p></li>
                <li><p>Documents (Word, Excel, Powerpoint, PDF)</p></li>
              </ul>
            </div>
          </Description>
        }
        primaryButtonText="Voir plus"
        primaryButtonUrl="/Idyaldrive"
        imageSrc="/images/odrive2.png"
        textOnLeft={true}
      />

      <MainFeature
        subheading={<Subheading></Subheading>}
        heading={
          <>
            <RecoveryHeading>Gestion du Recouvrement</RecoveryHeading>
            <wbr /> <HighlightedText>SIPAR</HighlightedText>
          </>
        }
        description={
          <Description>
            Un logiciel de dématérialisation de toute la chaîne du recouvrement fiscal. Il est composé de deux applications :
            <br />
            <div>
              <ul>
                <li><p>Web & GED</p></li>
                <li><p>Gestion des prises en charge (planification et Suivi)</p></li>
                <li><p>Formalités d'enregistrement</p></li>
                <li><p>Encaissement</p></li>
                <li><p>Contentieux</p></li>
                <li><p>Comptabilité</p></li>
                <li><p>Tableau de Bord</p></li>
              </ul>
            </div>
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Voir plus"
        imageSrc={"/images/sipar.png"}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />

      <MainFeature3
        subheading={<Subheading></Subheading>}
        heading={<>Recensement & Taxe foncière <HighlightedText>SIGUIL</HighlightedText></>}
        description={
          <Description>
            Un logiciel de dématérialisation des procédures de recensement et de calcul de la taxe foncière.
            <br />
            <div>
              <ul>
                <li><p>Web</p></li>
                <li><p>Mobile</p></li>
                <li><p>SIG</p></li>
                <li><p>GED</p></li>
                <li><p>Site des collectivités</p></li>
              </ul>
            </div>
          </Description>
        }
        primaryButtonText="Voir plus"
        primaryButtonUrl="/SIGUIL"
        imageSrc="/images/pagcf.png"
        textOnLeft={true}
      />
       <MainFeatures
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Gestion du Controle Fiscal<br />
             <HighlightedText>SISCOFI</HighlightedText>
          </>
        }

        description={
          <Description>
            Ce progiciel de dématérialisation de toutes les procédures du controle fiscal est composé de 3 Applications.
            
        
            
              <ul>
                <li>
                  <p>Web / GED / Algoriyhme de sélection</p>
                </li>
                <li>
                  <p>Gestion des contribuables</p>
                </li>
                <li>
                  <p>Programmation</p>
                </li>
                <li>
                  <p>Contrôle</p>
                </li>
                <li>
                  <p>Pilotge et suivi</p>
                </li>
                <li>
                  <p>Indicateurs d"évaluation statistiques</p>
                </li>
                <li>
                  <p>Administration & Paramétrage</p>
                </li>
                <li>
                  <p>Audit</p>
                </li>
                
              </ul>
          
            

            {/* <strong>WEB / GED / ALGORITHME DE SELECTION / ADMINISTRATION PARAMETRAGE / PILOTAGE ET SUIVI / INDICATEURS D’ÉVALUATIONS (STATISTIQUE) / PROGRAMMATION / CONTRÔLE  STATISTIQUES (TABLEAU DE BORD) / CONTRIBUABLE / AUDIT INTERCONEXION.</strong> */}
          </Description>
        }

        primaryButtonText="Voir plus"
        primaryButtonUrl="/siscofi"
        imageSrc="/images/siscofi-r.png"
        textOnLeft={true}
      />

<MainFeature3
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Prélèvement de Conformité Fiscale<br />
             <HighlightedText>PCF</HighlightedText>
          </>
        }

        description={
          <Description>
           Ce logiciel simplifie et centralise les processus liés à la conformité fiscale pour une gestion optimisée des contribuables. Il inclut plusieurs fonctionnalités clés :
              <ul>
                <li>
                  <p>Intégration de la liste des contribuables en conformité avec la DGID</p>
                </li>
                <li>
                  <p>Téléchargement de l’attestation de conformité</p>
                </li>
                <li>
                  <p>Demande de non-assujettissement</p>
                </li>
                <li>
                  <p>Validation ou rejet de la demande</p>
                </li>
                <li>
                  <p>Déclaration d’existence</p>
                </li>
                <li>
                  <p>Tableaux de bord</p>
                </li>
              </ul>
          
            

            {/* <strong>WEB / GED / ALGORITHME DE SELECTION / ADMINISTRATION PARAMETRAGE / PILOTAGE ET SUIVI / INDICATEURS D’ÉVALUATIONS (STATISTIQUE) / PROGRAMMATION / CONTRÔLE  STATISTIQUES (TABLEAU DE BORD) / CONTRIBUABLE / AUDIT INTERCONEXION.</strong> */}
          </Description>
        }

        primaryButtonText="Voir plus"
        primaryButtonUrl="/pcf"
        imageSrc="/images/pcf.png"
        textOnLeft={true}
      />

<MainFeature4
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Validation des adresses physiques<br />
             <HighlightedText>SIVAP</HighlightedText>
          </>
        }

        description={
          <Description>
            Ce système assure la vérification et la validation en temps réel des adresses physiques des contribuables pour une gestion optimisée des données d’adressage. Il inclut les fonctionnalités suivantes :

              <ul>
                <li>
                  <p>Croisement des données</p>
                </li>
                <li>
                  <p>Vérification en temps réel de l’adresse du contribuable</p>
                </li>
                <li>
                  <p>Exposition des données d’adressage des contribuables aux applications du SI</p>
                </li>
                <li>
                  <p>Gestion de la distribution du courrier</p>
                </li>
                <li>
                  <p>Transmission automatique des accuses de réception par le coursier à travers l’application mobile</p>
                </li>
              </ul>
          
            

            {/* <strong>WEB / GED / ALGORITHME DE SELECTION / ADMINISTRATION PARAMETRAGE / PILOTAGE ET SUIVI / INDICATEURS D’ÉVALUATIONS (STATISTIQUE) / PROGRAMMATION / CONTRÔLE  STATISTIQUES (TABLEAU DE BORD) / CONTRIBUABLE / AUDIT INTERCONEXION.</strong> */}
          </Description>
        }

        primaryButtonText="Voir plus"
        primaryButtonUrl="/sivap"
        imageSrc="/images/sivapajout.png"
        textOnLeft={true}
      />
    </AnimationRevealPage>
  );
}
