import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

// import MainFeature1 from "components/features/TwoColWithButton.js";
import OrangedriveSection from "./ValidationadSection";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-pink-700 hover:text-pink-700`}
  }
`;
export default () => {
  
  return (
    <>
    <StyledHeader><Header /></StyledHeader>
    <AnimationRevealPage>
      <OrangedriveSection
        subheading={<Subheading>A propos de</Subheading>}
        heading="Validation des Adresses Physiques"
        buttonRounded={false}
        // primaryButtonText="Voir Réalisations"
        imageSrc="/images/sivapmobile.png"
      />
     
      
      <Footer />
    </AnimationRevealPage>
    </>
  );
};
