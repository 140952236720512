import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line
import Accueil from "demos/Accueil.js";
import Telecom from "pages/Formations/Telecom";
import Gestionprojet from "pages/Formations/Gestionprojet";
import Reseaux from "pages/Formations/Reseaux";
import Devops from "pages/Formations/Devops";
import Formation from "pages/Formations.js";
import Apropos from "pages/Apropos.js";
import Contact from "pages/Contact.js";
import Realisation from "pages/Realisations.js";
import Reference from "pages/Reference.js";
import Siscofi from "pages/Realisations/Siscofi";
import Idyaldrive from "pages/Realisations/Idyaldrive";
import Mobileodrive from "pages/Realisations/Mobileodrive";
import Ged from "pages/Realisations/Ged";
import Pagcf from "pages/Realisations/Pagcf";
import Siguil from "pages/Realisations/Siguil";
import Sipar from "pages/Realisations/Sipar";
import Sivap from "pages/Realisations/Sivap";
import Envint from "pages/Realisations/Envint";
import Plateformecicd from "pages/Realisations/Plateformecicd";
import Taxe from "pages/Realisations/Taxe";
import Validationad from "pages/Realisations/Validationad";
import Recensementand from "pages/Realisations/Recensementand";
import Pagcfandroid from "pages/Realisations/Pagcfandroid";
import Comandroid from "pages/Realisations/Comandroid";
import Pcf from "pages/Realisations/Pcf";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";



export default function App() {
  
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<Accueil/>} />
          <Route path="/a-propos" element={<Apropos />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/realisation" element={<Realisation />} />
          <Route path="/formation" element={<Formation />} />
          <Route path="/reference" element={<Reference />} />
          <Route path="/devops" element={<Devops />} />
          <Route path="/reseaux" element={<Reseaux />} />
          <Route path="/gestionprojet" element={<Gestionprojet />} />
          <Route path="/telecom" element={<Telecom />} />
          <Route path="/siscofi" element={<Siscofi />} />
          <Route path="/Idyaldrive" element={<Idyaldrive />} />
          <Route path="/Mobileodrive" element={<Mobileodrive />} />
          <Route path="/Ged" element={<Ged />} />
          <Route path="/Pagcf" element={<Pagcf />} />
          <Route path="/Siguil" element={<Siguil />} />
          <Route path="/Sipar" element={<Sipar />} />
          <Route path="/Sivap" element={<Sivap />} />
          <Route path="/Envint" element={<Envint />} />
          <Route path="/Plateformecicd" element={<Plateformecicd />} />
          <Route path="/Taxe" element={<Taxe />} />
          <Route path="/Validationad" element={<Validationad />} />
          <Route path="/Recensementand" element={<Recensementand />} />
          <Route path="/Pagcfandroid" element={<Pagcfandroid />} />
          <Route path="/Comandroid" element={<Comandroid />} />
          <Route path="/Pcf" element={<Pcf />} />
        </Routes>
      </Router>
    </>
  );
}


