import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Column = styled.div`
  ${tw`lg:w-1/4  md:w-auto max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-10 h-10`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-gray-600 leading-none hocus:text-pink-700 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default ({
  cards = [
    {
      imageSrc: "/images/amssa.jpg",
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      
    },
    {
      imageSrc: "/images/dgid.jpg",
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      
    },
    {
      imageSrc: "/images/ageroute.jpg",
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      
    },
    {
      imageSrc: "/images/lapeyre.jpg",
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      
    },
    {
      imageSrc: "/images/bnde.jpg",
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      
    },
    {
      imageSrc: "/images/oilibya.jpg",
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      
    },
    {
      imageSrc: "/images/gainde.jpg",
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      
    },
    {
      imageSrc: "/images/cofina.png",
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      
    },
    {
      imageSrc: "/images/cese.png",
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      
    },
    {
      imageSrc: "/images/sgbs.jpg",
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      
    },
    {
      imageSrc: "/images/coursdescomptes.png",
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      
    },
    {
      imageSrc: "/images/bnp.jpg",
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      
    }
  ],
  linkText = "Voir site",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
    
     
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
               
                  <img src={card.imageSrc} alt="" css={imageCss} />
             
                {/* <span className="title">{card.title}</span> */}
                {/* <p className="description">{card.description}</p> */}
                {linkText && (
                  <span className="link">
                    {/* <span>{linkText}</span> */}
                    {/* <ArrowRightIcon className="icon" /> */}
                  </span>
                )}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      
      <DecoratorBlob />
   
    </Container>
  );
};
