import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container,  ContentWithPaddingXlA } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
// import Header from "components/headers/light.js";


const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Formation Devops" }) => {
  return (
    <AnimationRevealPage>
     
      <Container>
        <ContentWithPaddingXlA>
          
          <Text>
            {/* <p>Derniere mise a jour: Mardi 14 février 2023</p>

            <p>
            lorem ipsum dolor sit amet consectetur adipiscing elit
            </p>

            <p>
            lorem ipsum dolor sit amet consectetur adipiscing elit
            </p> */}

            <h1>LES FONCTIONNALITÉS PRINCIPALES</h1>
            
            <ul>
              <li>
                <p>
                  Gestion et de pilotage du contrôle fiscal
                </p>
              </li>
              <li>
                <p>
                  Intégration des objectifs du contrôle fiscal ; objectifs quantitatifs (délais et montants) et leur répartition par type de contrôle et par service
                </p>
              </li>
              <li>
                Sélection des contribuables selon différentes méthodes (propositions, scoring du risque avec du machin learning, sélection aléatoire, par renseignement)

              </li>
              <li>
                Imputation des dossiers sélectionnés aux services opérationnels de la DGID, aux inspecteurs et contrôleurs des impôts

              </li>
              <li>
                Collecte de données en temps réel sur l'état, les étapes des différents types de contrôle par service et par inspecteur-contrôleur

              </li>{" "}
              <li>
                Production de rapport pour les réunions de coordination et autres besoins de la Direction générale 

              </li>
              <li>
                Production de micro données pour les analyses économétriques sur le contrôle fiscal
              </li>
              <li>
                Contribuer à l'amélioration du taux de couverture du contrôle fiscal
              </li>
              <li>
                GED avec workflow de génération et de transmission de tous les documents (avis, notification, confirmation, TP, PV, demande de renseignement etc.) 

              </li>
              <li>
                Validation de document avec génération de QR code
              </li>
              <li>
                Traçabilité des actions 
              </li>
             
              
              
            </ul>  

          </Text>
        </ContentWithPaddingXlA>
      </Container>
     
    </AnimationRevealPage>
  );
};
