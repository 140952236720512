import React from "react";
import tw from "twin.macro";
import {ReactComponent as EmailNewsletterIconBase } from "../../images/phone.svg"
import {ReactComponent as LocationIconBase } from "../../images/location.svg"
import {Container as ContainerBase } from "components/misc/Layouts.js"
import {SectionHeading} from "components/misc/Headings.js";


const Container = tw(ContainerBase)`bg-violet-600 -mx-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Row = tw.div`flex items-center justify-center flex-col lg:flex-row px-8`
const TextColumn = tw.div`flex items-center flex-col sm:flex-row`
const EmailNewsletterIcon = tw(EmailNewsletterIconBase)`w-24 h-24 text-green-500`
const LocationIcon = tw(LocationIconBase)`w-26 h-26 text-green-500`
const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`
const Heading = tw(SectionHeading)`text-gray-100 sm:text-left leading-none`
const Description = tw.p`text-gray-500 font-medium text-sm max-w-sm mt-2 sm:mt-1 text-center sm:text-left`



export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <TextColumn>
            <EmailNewsletterIcon />
            <HeadingInfoContainer>
              <Heading>77 609 06 61</Heading>
           
           
            </HeadingInfoContainer>
          </TextColumn>
          
          <TextColumn>
            <LocationIcon />
            <HeadingInfoContainer>
              <Heading>Dakar, Sénégal</Heading>
              <Description>Point-E Rue de Thies X Rue de Fatick</Description>
            </HeadingInfoContainer>
          </TextColumn>
        </Row>
      </Content>
    </Container>
  );
};
