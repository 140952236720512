import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { motion} from "framer-motion";
//import StatsIllustrationSrc from "images/Durabilité.svg";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = styled.div`
  ${tw`relative my-20 mx-auto`}
  padding: 2rem;
  background-color: #ffffff; /* Couleur de fond blanche */
  border-radius: 20px;
  max-width: 1200px; /* Limite la largeur maximale du conteneur */
  text-align: center; /* Centre le contenu horizontalement */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); /* Ajout d'une ombre pour l'effet box-shadow */
`;

const TwoColumn = styled.div`
  ${tw`flex flex-col justify-center items-center w-full`}
  gap: 2rem;
`;

const TextColumn = styled.div`
  ${tw`w-full mt-16 relative`};
  max-width: 850px; /* Limite la largeur maximale de la colonne de texte */
  margin: 0 auto; /* Centre la colonne de texte */
  
`;

const TextContent = tw.div`lg:py-8 relative`;
const Subheading = tw(SubheadingBase)`text-center`;
const Heading = styled(SectionHeading)`
  ${tw`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl leading-tight text-center`}
  color: #B83280; /* Couleur #B83280 pour "Groupe Idyal" */
`;

const Description = styled.p`
  ${tw`mt-4 text-center text-base lg:text-lg font-medium leading-relaxed text-secondary-100`}
  color: #333333; /* Texte plus noir */
  margin-bottom: 2rem; /* Espace ajouté entre la description et "Groupe Idyal" */
`;

const Statistics = styled.div`
  ${tw`flex flex-row justify-center items-center text-center mt-8`} /* Espace au-dessus des statistiques */
`;

const Statistic = styled.div`
  ${tw`flex flex-col items-center inline-block sm:mr-12 last:mr-0 mt-4`}
  color: #333333; /* Texte plus noir */
  font-size: 1.25rem; /* Agrandir les statistiques */
  font-weight: bold;
  &:hover {
    color: #B83280; /* Effet de mise en exergue au survol */
  }
`;

const Value = tw.div`font-bold text-2xl tracking-wide`;
const Key = tw.div`font-medium text-lg`;

const MotionButton = motion(styled(PrimaryButtonBase)`
  ${tw`mt-8 text-sm inline-block px-8 py-4 sm:text-lg sm:px-6 sm:py-2`} /* Agrandir le bouton */
  margin-top: 4rem; /* Descendre le bouton */
  &:hover {
    background-color: #ffffff; /* Background blanche lors du survol */
    border-bottom: 2px solid #B83280; /* Bordure rose lors du survol */
    color: #B83280; /* Texte rose lors du survol */
  }
`);


export default ({
  subheading = "GROUPE IDYAL",
  heading = (
    <>
      Nous le faisons <wbr /> depuis <span tw="text-pink-700">2013</span>
    </>
  ),
  description = "Depuis 2013, nous innovons sans relâche, avec un service client exceptionnel et un fort engagement en matière de responsabilité sociale. Notre succès repose sur des années d'efforts dévoués pour offrir des solutions de qualité à nos clients.",
  primaryButtonText = "Voir plus",
  primaryButtonUrl = "./realisation",
  statistics = null,
}) => {
  const defaultStatistics = [
    { key: "Références", value: "50+" },
    { key: "Projets", value: "100+" },
    { key: "Partenaires", value: "11+" },
    { key: "Bureaux", value: "2+" }
  ];

  if (!statistics) statistics = defaultStatistics;

  //const { scrollYProgress } = useScroll();
  //const scaleImage = useTransform(scrollYProgress, [0, 0.4], [0.6, 1.1]);

  return (
    <Container>
      <TwoColumn>
        <TextColumn>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics>
            <MotionButton
              as="a"
              href={primaryButtonUrl}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              {primaryButtonText}
            </MotionButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
