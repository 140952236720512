import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/whoweare.svg";
import {ReactComponent as SvgDotPattern } from "images/svg-decorator-blob-3.svg"

const Container = styled.div`
  ${tw`relative my-40`}  // Ajout de margin top et bottom
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1); /* Box-shadow ajouté */
  border-radius: 20px; /* Bord arrondi pour un style plus moderne */
  padding: 2rem; /* Ajout de padding pour espacer le texte des bords */
  background-color: rgba(255, 255, 255, 0.8); /* Couleur de fond légère */
`;
const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-between items-center max-w-screen-xl w-full pb-5`}
  gap: 2rem; /* Ajustement de l'espace entre les colonnes */
`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = styled(Column)`
  ${tw`md:w-5/12 flex-shrink-0 relative flex justify-center items-center`}
  height: 100%; /* Assurer que l'image prend toute la hauteur */
`;

const TextColumn = styled(Column)`
  ${tw`md:w-7/12 mt-16 md:mt-0 flex flex-col justify-center`}
  padding: 1.5rem; /* Ajouter un espacement interne pour le texte */
`;

const Image = styled.img(props =>  [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw``
]);


export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Qui sommes<span tw="text-pink-700"> Nous ?</span>
    </>
  ),
  description = "En tant qu'entreprise informatique en constante évolution, nous nous efforçons toujours de développer de nouvelles technologies et de nouveaux services pour offrir une expérience utilisateur exceptionnelle. Nous sommes fiers de notre engagement envers l'excellence et nous nous efforçons de maintenir un niveau élevé de satisfaction client à chaque étape du processus. ",
  primaryButtonText = "Voir plus",
  primaryButtonUrl = "#",
  imageSrc = TeamIllustrationSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image css={imageCss} src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded}/>
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <PrimaryButton buttonRounded={buttonRounded} as="a" href="/siscofi">
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
