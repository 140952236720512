import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import Header from "components/headers/light.js";
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import styled from "styled-components";
import tw from "twin.macro";
import FAQ from "components/faqs/SingleCol.js";
import TabGrid from "components/cards/TabCardGrid.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-pink-700 hover:text-pink-700`}
  }
`;
const HighlightedText = tw.span`bg-pink-700 text-gray-100 px-4 transform -skew-x-12 inline-block`;
export default () => {
  return (
    <>
    <StyledHeader><Header /></StyledHeader>
    <AnimationRevealPage>
       <TabGrid
        heading={
          <>
            Nos <HighlightedText>Formations.</HighlightedText>
          </>
        }
      />
      <FAQ />
      <Footer/>
    </AnimationRevealPage>
    </>
  );
};
