import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleSubscribeNewsletter from "components/forms/SimpleSubscribeNewsletter";
import tw from "twin.macro";
import styled from "styled-components"; 
import { css } from "styled-components/macro"; //eslint-disable-line
// import Header from "components/headers/light.js";
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";

// const Address = tw.span`leading-relaxed`;
// const AddressLine = tw.span`block`;
// const Email = tw.span`text-sm mt-6 block text-gray-500`;
// const Phone = tw.span`text-sm mt-0 block text-gray-500`;

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-pink-700 hover:text-pink-700`}
  }
`;
export default () => {
  return (
    <>
    <StyledHeader><Header /></StyledHeader>
    <AnimationRevealPage>
      <ContactUsForm />
      <SimpleSubscribeNewsletter />
      <Footer />
    </AnimationRevealPage>
    </>
  );
};
