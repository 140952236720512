import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";
import TeamIllustrationSrc from "images/whoweare2.jpg";
import { ReactComponent as SvgDotPattern } from "images/svg-decorator-blob-3.svg";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";


const Container = styled.div`
  ${tw`relative my-40`}
  padding: 2rem;
  background-color: #ffffff; 
  border-radius: 20px; 
  max-width: 1200px;
  text-align: center;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
`;

const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-0 md:py-24 items-center`}
  gap: 1rem; /* Réduction de l'espacement entre les colonnes (texte et image) */
`;

const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = styled(Column)`
  ${tw`md:w-6/12 flex-shrink-0 relative`}
  height: 100%; /* Assurer que l'image prenne toute la hauteur du conteneur */
`;

const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-12 md:order-first` : tw`md:ml-8 lg:ml-12 md:order-last`
]);

const Image = styled.img`
  ${tw`w-full h-full object-cover`} /* L'image prend toute la hauteur et largeur de son conteneur */
  border-radius: 10px; /* Ajuster l'arrondi si nécessaire */
`;

const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = styled(SectionHeading)`
  ${tw`w-full text-3xl sm:text-4xl lg:text-5xl leading-tight text-center font-black`}
  color: #B83280;
`;

const Description = styled.p`
  ${tw`mt-4 text-center md:text-justify text-base lg:text-lg font-medium leading-relaxed text-secondary-100`}
  color: #333333;
  margin-bottom: 2rem;
`;

const MotionButton = motion(styled(PrimaryButtonBase)`
  ${tw`mt-8 text-sm inline-block px-8 py-4 sm:text-lg sm:px-6 sm:py-2`} 
  &:hover {
    background-color: #ffffff; 
    border-bottom: 2px solid #B83280; 
    color: #B83280;
  }
`);


export default ({
  subheading = "Our Expertise",
  heading = (
    <>
     <span> Qui sommes nous ?</span>
    </>
  ),
  description = "En tant qu'entreprise technologique spécialisée en développement informatique, cybersécurité, audit, conseil et formation, nous concevons en permanence des solutions innovantes pour offrir une expérience utilisateur de premier ordre. Notre engagement envers l'excellence se reflète dans la satisfaction élevée de nos clients à chaque étape de notre collaboration",
  primaryButtonText = "Voir plus",
  primaryButtonUrl = "/realisation",
  imageSrc = TeamIllustrationSrc,
  textOnLeft = true
}) => {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image
            src={imageSrc}
          />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <MotionButton
              as="a"
              href={primaryButtonUrl}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              {primaryButtonText}
            </MotionButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
