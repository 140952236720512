import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import Section from "./section1";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SimpleSubscribeNewsletter from "components/forms/SimpleSubscribeNewsletter";

import Hero from "components/hero/BackgroundAsImage.js";
import Video from "components/hero/Video.js";
import Brands from "components/hero/Brands.js"
import Services from "components/features/ThreeColWithSideImage.js";
// import TechnologieUse from "components/features/TechnologieUse";

import MainFeatures from "components/features/TwoColWithButton.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/cards/ThreeColSlider";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Footer from "components/footers/FiveColumnWithInputForm";
import { SectionHeading } from "components/misc/Headings.js";


const Heading = tw(SectionHeading)`w-full`;
const Subheading = tw.span`uppercase tracking-widest font-bold text-pink-700`;
const HighlightedText = tw.span`bg-gray-400 text-pink-100 px-4 transform -skew-x-12 inline-block`;
export default () => (
  <AnimationRevealPage>
    
    <Hero />
    <Video />
    <MainFeature />
    <MainFeatures
        subheading={<Subheading>Groupe IDYAL</Subheading>}
        
        imageBorder={true}
        imageDecoratorBlob={true}
      />
    <Services />
    <Portfolio />
    {/* <Section /> */}
    <Testimonial
      subheading="Témoignages"
      heading={
        <>
          Ils nous font <span tw="text-pink-700">Confiance.</span>
        </>
      }
      // description="Nous sommes fiers de compter de nombreuses entreprises et organisations parmi nos clients fidèles qui nous font confiance pour leur fournir des services informatiques de qualité. Nous avons établi une réputation solide en matière de fiabilité, de qualité et de support client exceptionnel. Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins et leurs objectifs, et nous nous efforçons toujours de les dépasser. Notre engagement envers la satisfaction de nos clients est sans faille, et nous continuons de faire tout notre possible pour les aider à atteindre leur plein potentiel."
      // testimonials={[
      //   {
      //     imageSrc:
      //       "/images/dev.jpg",
      //     profileImageSrc:
      //       "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
      //     quote:
      //       "Leur équipe a été très professionnelle et attentive à nos besoins, ce qui a permis de livrer un produit final de haute qualité.",
      //     customerName: "Fatou KEBE",
      //     customerTitle: "Chef de Projet, DGID."
      //   },
      //   {
      //     imageSrc:
      //       "/images/team.jpg",
      //     profileImageSrc:
      //       "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
      //     quote:
      //       "Nous sommes extrêmement reconnaissants pour le support continu et les conseils avisés que nous avons reçus de cette entreprise informatique. Ils nous ont aidé à optimiser notre infrastructure informatique et à améliorer notre productivité de manière significative.",
      //     customerName: "Youssouf",
      //     customerTitle: "Directeur, Orange Cote d'Ivoire"
      //   }
      // ]}
      textOnLeft={true}
    />
    
    <Blog />
    <br/>
    <br/>
    <br/>
    {/* <TechnologieUse /> */}
    <Heading>Nos <HighlightedText><span tw="text-pink-700">Partenaires</span></HighlightedText></Heading>
    <Brands />
    <ContactUsForm />
    <SimpleSubscribeNewsletter />
    <Footer />
  </AnimationRevealPage>
);
