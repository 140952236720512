import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXlA } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import TwoColumnWithVideoLeft from "components/hero/TwoColumnWithVideoLeft";

const Text = styled.div`
  ${tw`text-lg text-gray-700 text-center`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-4xl font-extrabold mt-10 mb-6`}
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    color: #243E63; /* Updated title color */
  }
  ul {
    ${tw`list-disc pl-5 inline-block text-left`} /* Updated to list-disc for standard bullets */
    li {
      ${tw`mb-5`}
      p {
        ${tw`ml-3 text-base`}
      }
    }
  }
`;

export default ({ headingText = "Formation Devops" }) => {
  return (
    <AnimationRevealPage>
      <Container>
        <ContentWithPaddingXlA>
          <Text>
            <h1>LES FONCTIONNALITÉS PRINCIPALES</h1>
            <ul>
              <li>
                <p>Gestion et pilotage du contrôle fiscal</p>
              </li>
              <li>
                <p>Intégration des objectifs du contrôle fiscal ; objectifs quantitatifs (délais et montants) et leur répartition par type de contrôle et par service</p>
              </li>
              <li>
                <p>Sélection des contribuables selon différentes méthodes (propositions, scoring du risque avec du machine learning, sélection aléatoire, par renseignement)</p>
              </li>
              <li>
                <p>Imputation des dossiers sélectionnés aux services opérationnels de la DGID, aux inspecteurs et contrôleurs des impôts</p>
              </li>
              <li>
                <p>Collecte de données en temps réel sur l'état, les étapes des différents types de contrôle par service et par inspecteur-contrôleur</p>
              </li>
              <li>
                <p>Production de rapport pour les réunions de coordination et autres besoins de la Direction générale</p>
              </li>
              <li>
                <p>Production de micro données pour les analyses économétriques sur le contrôle fiscal</p>
              </li>
              <li>
                <p>Contribuer à l'amélioration du taux de couverture du contrôle fiscal</p>
              </li>
              <li>
                <p>GED avec workflow de génération et de transmission de tous les documents</p>
              </li>
              <li>
                <p>Validation de document avec génération de QR code</p>
              </li>
              <li>
                <p>Traçabilité des actions</p>
              </li>
            </ul>
          </Text>
        </ContentWithPaddingXlA>
        <TwoColumnWithVideoLeft />
      </Container>
    </AnimationRevealPage>
  );
};
