import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Vision from "components/features/vision.js";
import Features from "components/features/ThreeColWithSideImage.js";
import TechnologieUse from "components/features/TechnologieUse";

// Importe les images locales ici
import LocalImage1 from "images/apropos.jpg"; // remplace par le nom et chemin de ton image
import LocalImage2 from "images/apropos2.jpg"; // remplace par le nom et chemin de ton image

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-pink-700 hover:text-pink-700`}
  }
`;

export default () => {
  
  return (
    <>
    <StyledHeader><Header /></StyledHeader>
    <AnimationRevealPage>
      
      <MainFeature1
        subheading={<Subheading>Groupe idyal</Subheading>}
        heading="A propos"
        buttonRounded={false}
        primaryButtonText="Voir Réalisations"
        imageSrc={LocalImage1} // Utilise l'image locale ici
      />
      <Vision
       subheading={<Subheading>Groupe idyal</Subheading>}
        heading="Notre Vision"
        buttonRounded={false}
        primaryButtonText="Nous contacter"
        imageSrc={LocalImage2} // Utilise l'image locale ici
        textOnLeft={false}
      />
      <div class="plyr__video-embed" id="player">
  {/* <iframe
    src="https://www.youtube.com/embed/bTqVqk7FSmY?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"
    allowfullscreen
    allowtransparency
    allow="autoplay"
  ></iframe> */}
      </div>
      <Features />
      <FeatureStats />
      <TechnologieUse />
      {/* <TeamCardGrid 
        subheading={<Subheading>L'équipe</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
    </>
  );
};
