import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import LogoImage from "images/logo.png";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mx-8 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`w-1/2 md:w-1/5 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-24`;
const CompanyInfo = tw.div`justify-center items-center flex md:justify-start`

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:justify-start flex justify-center`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const CopyrightNotice = tw.div``
const LogoText = tw.h5`ml-2 text-xl font-black`;
const FiveColumns = tw.div`flex flex-wrap justify-between`;
const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`;
const LogoContainers = tw.div`flex items-center justify-center lg:justify-start`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;
const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

export default () => {
  return (
    <Container>
    <Content>
      <FiveColumns>
        <CompanyColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText></LogoText>
          </LogoContainer>
          <CompanyAddress>
            Sénégal <br/>
            Dakar, Point-E <br/>
            Rue Thies X Rue Fatick <br/>
            contact@groupeidyal.com
          </CompanyAddress>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </CompanyColumn>
        <Column>
          <ColumnHeading>Applications<br/> WEB</ColumnHeading>
          <LinkList>
              <LinkListItem>
                <Link href="/siscofi">SISCOFI</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/sipar">SIPAR</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/sivap">SIVAP</Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="/siguil">SIGUIL</Link>
              </LinkListItem> */}
              <LinkListItem>
                <Link href="/idyaldrive">Idyal Drive</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/ged">GED</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/pagcf">SIGUIL</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/pcf">PCF</Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="/a-propos">A propos</Link>
              </LinkListItem> */}
            </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Applications <br/> mobiles</ColumnHeading>
          <LinkList>
              <LinkListItem>
                <Link href="/mobileodrive">Idyal Drive <br/> Android</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/pagcfandroid">SIGUIL Android</Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="/recensementand">Recensement petit <br/> Commerce Android</Link>
              </LinkListItem> */}
              {/* <LinkListItem>
                <Link href="/taxe">Recensement des Biens <br/> Assujettie a la Taxe <br/> Android</Link>
              </LinkListItem> */}
              <LinkListItem>
                <Link href="/validationad">SIVAP Validation adresse  <br/> physique Android</Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="/realisation">SISCOFI</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="realisation">ORANGE-DRIVE</Link>
              </LinkListItem> */}
            </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Integration <br/> Applicative</ColumnHeading>
          <LinkList>
              <LinkListItem>
                <Link href="/envint">Environnement d'integration <br/> continue</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/plateformecicd">Plateforme CI/CD</Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="#">Lorem</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="#">Lorem</Link>
              </LinkListItem> */}
            </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Formations</ColumnHeading>
          <LinkList>
          <LinkListItem>
                <Link href="/formation">Devops</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/formation">Gestion de Projet</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/formation">Marketing</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/formation">Telecom</Link>
              </LinkListItem>
          </LinkList>
        </Column>
      
      </FiveColumns>
      <Divider/>
      <CopyrightAndCompanyInfoRow>
        <CopyrightNotice>&copy; Copyright 2013, Groupe IDYAL.</CopyrightNotice>
        <CompanyInfo>Tous droits reservés.</CompanyInfo>
      </CopyrightAndCompanyInfoRow>
    </Content>
  </Container>
  );
};
