import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import defaultCardImage from "images/shield-icon.svg";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import Developer from "images/developer.svg";
import Backup from "images/fast.svg";
import Audit from "images/audit.svg";
import Licence from "images/licence.svg";

// Conteneur principal avec fond blanc et box-shadow
const Container = styled.div`
  ${tw`relative my-20 mx-auto`}
  padding: 2rem;
  background-color: #ffffff; /* Fond blanc */
  border-radius: 20px;
  max-width: 1200px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); /* Ajout d'une ombre */
`;

const SectionDescriptions = styled(DescriptionBase)`
  ${tw`text-center mx-auto text-gray-600 max-w-4xl`}
  color: #333333;
`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-wrap justify-center max-w-screen-lg mx-auto pt-10`}
`;

const Heading = styled(SectionHeading)`
  ${tw`w-full text-3xl sm:text-4xl lg:text-5xl leading-tight text-center font-black`}
  color: #B83280; /* Couleur #B83280 pour les titres */
`;

const VerticalSpacer = tw.div`w-full`;

const Column = styled.div`
  ${tw`w-full sm:w-1/2 lg:w-1/4 max-w-xs flex flex-col items-center`};
`;

const Card = styled.div`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-2 py-8`};
  
  .imageContainer {
    ${tw`border text-center rounded-full p-5 bg-white`}  // Fond blanc ajouté ici
    img {
      ${tw`w-16 h-16`};
      transition: transform 0.3s ease-in-out;  // Animation pour les SVG au hover
      &:hover {
        transform: scale(1.2);  // Agrandissement au survol
      }
    }
  }

  .textContainer {
    ${tw`mt-4`};
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-xl leading-none`};
  }
color: #333333;`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `};
`;



export default ({
  cards = null,
  heading = "Nos Services",
  description = "Nous proposons une gamme complète de solutions adaptées aux besoins divers de nos clients. Nos services englobent la gestion de projets, le développement de logiciels, le déploiement de réseaux, la cybersécurité, la sauvegarde de données, ainsi que le support technique, l’audit, le conseil et la formation.",
}) => {
  const defaultCards = [
    { imageSrc: Developer, title: "Développement de logiciels" },
    { imageSrc: Backup, title: "Backup & Déploiement" },
    { imageSrc: Audit, title: "Conseils & Audit" },
    { imageSrc: Licence, title: "Formation" }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>
          {heading} 
        </Heading>
        <SectionDescriptions>{description}</SectionDescriptions>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt={card.title || "Service"} />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
