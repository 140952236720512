import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-12 xl:last:mr-0`;
const HeadingColumn = styled(Column)(props => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`
]);
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-3/12 mt-16 xl:mt-0 xl:last:ml-auto`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg text-justify font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`text-pink-800 font-bold text-lg`;
const CardType = tw.div`font-semibold text-sm text-gray-600`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-center sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4 mr-4 last:mr-0`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;



export default ({
  subheading = "Notre expertise aux services de nos partenaires",
  headingHtmlComponent = (
    <>
      Nous avons réalisé <span tw="text-pink-700">des projets fabuleux.</span>
    </>
  ),
  description = "Notre expertise en services informatiques constitue un atout stratégique pour nos partenaires. Avec une équipe hautement qualifiée et expérimentée, nous concevons des solutions sur mesure, adaptées aux besoins spécifiques de chacun. Nous nous engageons à offrir un service de qualité exceptionnelle, en adoptant une approche centrée sur les objectifs de nos partenaires pour les aider à les atteindre. La collaboration est au cœur de notre démarche, favorisant des relations solides et durables et assurant le succès de nos partenaires.",
  linkText = "Voir tous nos projets",
  cardLinkText = "Voir projet",
  textOnLeft = false
}) => {
  const cards = [
    {
      imageSrc:
        "https://img.freepik.com/free-photo/african-american-businessman-using-laptop-cafe_1157-46898.jpg?t=st=1730224436~exp=1730228036~hmac=b31e1d40707ba2341e3de95c20d69768363ca01683ef079539e88c1ad0f908ce&w=360",
      company: "DGID.",
      type: "Gestion des recensements",
      title: "Gestion Fiscale, Digitalisation du processus de Recensement",
      durationText: "100 jours ",
      locationText: "Dakar, Sénégal"
    },
    {
      imageSrc:
        "https://img.freepik.com/free-photo/industrial-designers-working-3d-model_23-2149370135.jpg?t=st=1730224472~exp=1730228072~hmac=d51024634dd94ec289fc6b36e00470e68e0f036e5d6d3dbb2050fb75bca941b4&w=360",
      company: "",
      type: "Idyal Drive",
      title: "Service cloud #1 sauvegarde de vos fichiers",
      durationText: "2 mois de production",
      locationText: ""
    }
  ];
  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn textOnLeft={textOnLeft}>
            <HeadingInfoContainer>
              <Subheading>{subheading}</Subheading>
              <HeadingTitle>{headingHtmlComponent}</HeadingTitle>
              <HeadingDescription>{description}</HeadingDescription>
              <PrimaryLink href="/realisation">
                {linkText} <ArrowRightIcon />
              </PrimaryLink>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                <CardImage imageSrc={card.imageSrc} />
                <CardText>
                  <CardHeader>
                    <CardCompany>{card.company}</CardCompany>
                    <CardType>{card.type}</CardType>
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                  <CardMeta>
                    {/* <CardMetaFeature>
                      <TimeIcon /> {card.durationText}
                    </CardMetaFeature> */}
                    <CardMetaFeature>
                      <LocationIcon /> {card.locationText}
                    </CardMetaFeature>
                  </CardMeta>
                  {/* <CardAction href="/realisation">{cardLinkText}</CardAction> */}
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
