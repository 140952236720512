import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
// import Header from "components/headers/light.js";
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-pink-700 hover:text-pink-700`}
  }
`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Formation Devops" }) => {
  return (
    <>
    <StyledHeader><Header /></StyledHeader>
    <AnimationRevealPage>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Derniere mise a jour: Mardi 14 février 2023</p>

            <p>
            Avec la culture DevOps, collaborateurs et clients travaillent ensemble pour accélérer le processus de développement, de déploiement et le temps de commercialisation.
            </p>

            <p>
               Groupe IDYAL vous propose le parcours DevOps pour forger cette culture au sein des entreprises et vous donne les outils nécessaires pour chaque phase depuis la conception et la planification jusqu'au déploiement et au monitoring.
            </p>

            <h1>Un contenu Riche</h1>
            {/* <h2>Interpretation</h2>
            <p>
              The words of which the initial letter is capitalized have meanings defined under the following conditions.
            </p>
            <p>
              The following definitions shall have the same meaning regardless of whether they appear in singular or in
              plural.
            </p> */}

            {/* <h2>Definitions</h2>
            <p>For the purposes of this Privacy Policy:</p> */}
            <ul>
              <li>
                <p>
                  <strong>Administration des réseaux</strong> Comprendre le modèle OSI. Comprendre l’adressage IP et le routage. Différencier entre NAT et DHCP et utiliser l'aide au dépannage.
                </p>
              </li>
              <li>
                <p>
                  <strong>DevOps</strong> Découvrir DevOps et ses avantages. Comprendre le cycle de vie DevOps.
                </p>
              </li>
              <li>
                <strong>CI/CD</strong> Différencier entre l’intégration continue et le déploiement continu.
                Découvrir en profondeur le développement continu et les tests continus.
                Découvrir en profondeur l'intégration continue, le déploiement continu et la livraison continue.
              </li>
              <li>
                <strong>Les conteneurs</strong> Comprendre ce qu'est un conteneur Docker. Découvrir et manipuler les composants de Docker.
                Dockeriser une application.
                Service.
              </li>
              <li>
                <strong>Orchestration</strong> Comprendre ce qu'est un orchestrateur et découvrir Kubernetes.
                Utiliser des objets Kubernetes.
                Orchestrer et déployer une application avec k8s.
              </li>{" "}
              <li>
                <strong>Amazon Web Services</strong> Découvrir le composant principal d'AWS.
                Découvrir le stockage continu en profondeur.
              </li>
              <li>
                <strong>La phase “Lab”</strong> Résumer les différentes étapes d’une bonne gestion de projet et appliquer toutes les connaissances acquises au cours du programme.
              </li>
             
              
              
            </ul>

            
           
           
           

          <h1>Qui peut nous rejoindre ?</h1>
           
            
            
            <ul>
              <li>
                <p>
                  <strong>Les personnes ayant 18 ans ou plus</strong>
                </p>
                
              </li>
              <li>
                <p>
                  <strong>Les passionnés de DevOps</strong>
                </p>
               
              </li>
              <li>
                <p>
                  <strong>Les personnes qui veulent se lancer dans le DevOps.</strong>
                </p>
                
              </li>
            </ul>
            

          <h1>Devenir DevOps</h1>
            
            <ul>
              <li>
                <strong>Apprendre à implémenter DevOps dans une organisation.</strong>
              </li>
              <li>
                <strong>Comprendre l’intégration continue et le déploiement continu.</strong> 
              </li>
              <li>
                <strong>Implémenter des pipelines.</strong>
              </li>
              <li>
                <strong>Optimisez votre déploiement en créant des conteneurs et orchestrer ces derniers à l'aide de Kubernetes.</strong> 
                
              </li>
              <li>
                <strong>Utiliser les outils: Jenkins, Docker et Kubernetes.</strong> 
              </li>
              <li>
                <strong>Comprendre et gérer les services d'AWS.</strong> 
              </li>
            </ul>

            

            <ul>
              <li>
                <strong>Être en mesure de maintenir une infrastructure “On-Premise” ou en utilisant des fournisseurs cloud.</strong> 
                
              </li>
              <li>
                <strong>Devenir DevOps.</strong> 
              </li>
              
             
            </ul>

            <h1>Se préparer aux métiers du futur</h1>
            <p>
            42% des offres d’emploi sont influencées par la digitalisation et on compte 400 000 nouveaux postes dans l'industrie numérique!
            Notre vision est de former les talents de demain, ceux qui pourront faire face, et même profiter, de cette digitalisation. Ce parcours vous permettra de devenir ingénieur Devops.
            </p>
            

            <h2>Pourquoi choisir ce programme ?</h2>
            <p>
              <strong>Accompagnement personnalisé: </strong>
              Chaque étudiant sera accompagné par un instructeur de haut niveau. Nous croyons fortement qu'une formation efficace passe par une assistance humaine.
            </p>
            <p>
              <strong>Notre plateforme éducative </strong>
              Une plateforme qui permet à l'étudiant d'accéder à tout moment aux supports de formation, d'organiser son emploi du temps avec son instructeur, de suivre sa performance et d'identifier ses points d'amélioration.
            </p>
            <p>
              <strong>Un contenu riche et interactif </strong>
              Le contenu de nos parcours est élaboré avec nos partenaires, il répond donc à leurs besoins et il est constamment mis à jour selon leur demande. Le contenu est aussi interactif et riche en exercices pratiques, mini-projets vidéos et images.
            </p>
            <p>
              <strong>Notre communauté </strong>
              En rejoignant Groupe IDYAL vous aurez accès au support de toute une communauté passionnée par le numérique pour vous motiver au quotidien.
            </p>

            <h1>Plusieurs milestones permettent à l'étudiant de maîtriser les compétences techniques:</h1>
            
            
            <ul>
              <li>
                <p>
                  <strong>Évaluations</strong> : ce sont des exercices rapides (quiz, coding exercices...) qui se trouvent après chaque compétence et qui permettent de tester la compréhension du cours par l’étudiant.
                </p> 
              </li>
              <li>
                <p>
                  <strong>Checkpoints</strong> : ce sont des mini-projets qui permettent à l'étudiant de mettre en pratique ce qu'il a appris et de se préparer pour le projet final. Les checkpoints sont ensuite corrigés et évalués par l'instructeur.
                </p> 
              </li>
              <li>
                <p>
                  <strong>One-to-one meetings</strong> : ce sont des réunions en one to one entre l'étudiant et l'instructeur. L'instructeur échange avec l'étudiant et lui pose plusieurs questions à à l'oral pour tester sa compréhension de ce qu'il a appris et lui faire un feedback sur les points à améliorer (entre 5 et 20 selon le programme).
                </p> 
              </li>
              <li>
                <p>
                  <strong>Workshops</strong> : ce sont des mini présentations animées par l'instructeur pendant lesquelles l'instructeur explique un concept ou résout un problème en direct avec les étudiants. Le but étant de consolider les connaissances de nos étudiants par le biais d'exercices pratiques.
                </p> 
              </li>
              <li>
                <p>
                  <strong>Projet final ou Lab phase</strong> : c'est le moment pendant lequel l'étudiant va mettre en pratique tout ce qu'il a appris pendant le programme et construire un vrai produit technologique. L'étudiant est assisté par l'instructeur durant toute cette phase.
                </p> 
              </li>
            </ul>

          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
    </>
  );
};
