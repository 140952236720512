import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import styled from "styled-components";
import Brands from "components/hero/Brands.js"
import tw from "twin.macro";


const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-pink-700 hover:text-pink-700`}
  }
`;

export default () => (
  <>
  <StyledHeader><Header /></StyledHeader>
  <AnimationRevealPage>
    <Brands />
    <Footer />
  </AnimationRevealPage>
  </>
);
