import React, { useState } from "react";
import ReactPlayer from 'react-player';
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.div`relative`;
const Column = tw.div`flex flex-col items-center justify-center max-w-screen-xl mx-auto py-20 md:py-24`;
const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl text-center`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 text-center max-w-lg mx-auto`;
const VideoContainer = tw.div`flex justify-center items-center w-full mt-8`;

// Décorateurs pour les blobs (optionnels)
const DecoratorBlob1 = styled.div`${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}`;
const DecoratorBlob2 = styled.div`${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}`;

export default ({
  heading = "Groupe IDYAL",
  description = "Vidéo de Présentation de Siscofi : Système Informatique de Suivi du Contrôle Fiscal.",
  watchVideoYoutubeUrl = "https://www.youtube.com/watch?v=EPx3esVR5Lo"
}) => {
  return (
    <Container>
      <Column>
        {/* Titre au-dessus de la vidéo */}
        <Heading>{heading}</Heading>
        
        {/* Description au-dessus de la vidéo */}
        <Paragraph>{description}</Paragraph>
        
        {/* Conteneur de la vidéo */}
        <VideoContainer>
          <ReactPlayer
            url={watchVideoYoutubeUrl}
            controls
            width="80%"  // Ajustement de la largeur de la vidéo
            height="500px"  // Ajustement de la hauteur de la vidéo
          />
        </VideoContainer>
      </Column>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
