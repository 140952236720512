import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container,  ContentWithPaddingXlA } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
// import Header from "components/headers/light.js";


const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Formation Devops" }) => {
  return (
    <AnimationRevealPage>
     
      <Container>
        <ContentWithPaddingXlA>
          
          <Text>
            {/* <p>Derniere mise a jour: Mardi 14 février 2023</p> */}

            <p>
            Cet Environnement est mis en place pour le Port Autonome de Dakar. L'objectif principal visé à travers ce projet est de garantir la mise en production
            </p>

            <p>
            d'un code de qualité et de formaliser les processus de production logicielle. L'usine logicielle est composé de : 
            </p>

            {/* <h1>LES FONCTIONNALITÉS PRINCIPALES</h1> */}
            
            <ul>
              <li>
                <p>
                  Outil de gestion de projet
                </p>
              </li>
              <li>
                <p>
                  Système de contrôle des versions 
                </p>
              </li>
              <li>
                Serveur d'intégration continue 

              </li>
              <li>
                Outils de build 

              </li>
              <li>
                Outils de tests automatisés 

              </li>{" "}
              <li>
                Outils de déploiement 

              </li>
              <li>
                Rapports et notifications 
              </li>
              
             
              
              
            </ul>  
            <h1>SERVICES RENDUS</h1>
              <ul>
                <li>
                  Analyse des outils de développement 
                </li>
                <li>
                  Analyse du cycle de création logiciel et de la planification 

                </li>
                <li>
                  Analyse de l'architecture 
                </li>
                <li>
                  Analyse des codes sources 
                </li>
                <li>
                  Analyse d'interfaces applicatives 
                </li>
                <li>
                  Analyse des mises en production 
                </li>
                <li>
                  Analyse des processus et outils de gestion des tests
                </li>
            </ul>

            <h1>FORMALISATION</h1>
              <ul>
                <li>
                  Formalisation des processus de production 
                </li>
                <li>
                  Définition de la planification continue 
                </li>
                <li>
                   Définition du cycle de vie des applications
                </li>
                <li>
                  Définition des indicateurs de qualité
                </li>
                <li>
                  DevOps 
                </li>
                <li>
                  Définition usine de tests 
                </li>
                <li>
                  Définition mécanisme de contrôle qualité 
                </li>
                <li>
                  Structuration de la gestion des demandes de changement 
                </li>
                <li>
                  Définition de l'architecture 
                </li>
                <li>
                  Choix des outils 
                </li>
                
              </ul>

              <h1>INSTALLATION ET PARAMETRAGE</h1>
              <ul>
                <li>
                  Identification et installation des serveurs 
                </li>
                <li>
                  Mise en place serveur de versionning
                </li>
                <li>
                  Mise en place serveur d'analyse de code
                </li>
                <li>
                  Automatisation VABF et VABE et assurance de non régression
                </li>
              </ul>

              <h1>TRANSFERT DE COMPETENCES, FORMATION ET CERTIFICATION</h1>
              <ul>
                <li>
                  Transfert de compétences sur les outils 
                </li>
                <li>
                  Transfert de compétences sur la mise en œuvre des outils
                </li>
                <li>
                  Ateliers pratiques
                </li>
                <li>
                  Formation sur ITIL (4 ressources maximum)
                </li>
                <li>
                  Examen de certification (2 ressources maximum)
                </li>
              </ul>

          </Text>
        </ContentWithPaddingXlA>
      </Container>
     
    </AnimationRevealPage>
  );
};
