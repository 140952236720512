import React from "react";
import Section from "demos/section1";
import Hero from "../components/hero/TwoColumnWithVideo";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
// import Header from "components/headers/light.js";
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
// import { PrimaryButton } from "components/misc/Buttons";
// import Blog from "components/blogs/GridWithFeaturedPost";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-pink-700 hover:text-pink-700`}
  }
`;



// const ButtonContainer = tw.div`flex justify-center`;
// const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
  headingText = "",
  posts = [
    // {
    //   imageSrc:
    //     "/images/siscofi.png",
    //   category: "Siscofi",
    //   date: "April 21, 2020",
    //   title: "Lorm ipsum dollor var glish",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    //   url: "dev-siscofi.groupeidyal.com",
    //   featured: true
    // },
    getPlaceholderPost(),
    getPlaceholderPost1(),
    getPlaceholderPost2(),
    getPlaceholderPost3(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost()
  ]
}) => {
  // const [visible, setVisible] = useState(7);
  // const onLoadMoreClick = () => {
  //   setVisible(v => v + 6);
  // };
  const HighlightedText = tw.span`bg-pink-700 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const imageCss = tw`rounded-4xl`;
  return (
    <>
    <StyledHeader><Header /></StyledHeader>
    <AnimationRevealPage>
     <Hero
        heading={<>Nos <HighlightedText>Réalisations.</HighlightedText></>}
        description="Nos réalisations témoignent de notre engagement envers la qualité et la satisfaction de nos clients. Nous sommes fiers de l’impact positif que nous avons pu apporter à leur expérience."
        imageSrc="/images/siscofi.png"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Parlons de votre projet"
        url="/Contact"
        watchVideoButtonText="Lire la vidéo"
      />
      <Section />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          {/* <Blog /> */}
          <Posts>
            {/* {posts.slice(0, visible).map((post, index) => ( */}
              {/* // <PostContainer key={index} featured={post.featured}> */}
                {/* <Post className="group" as="a" href={post.url}> */}
                  {/* <Image imageSrc={post.imageSrc} /> */}
                  {/* <Info> */}
                    {/* <Category>{post.category}</Category> */}
                    {/* <CreationDate>{post.date}</CreationDate> */}
                    {/* <Title>{post.title}</Title> */}
                    {/* {post.featured && post.description && <Description>{post.description}</Description>} */}
                  {/* </Info> */}
                {/* </Post> */}
              {/* // </PostContainer> */}
            {/* ))} */}
          </Posts>
          {/* {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Voir plus</LoadMoreButton>
            </ButtonContainer>
          )} */}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
    </>
  );
};

const getPlaceholderPost = () => ({
  imageSrc:
  "/images/siguil.png",
  category: "Groupe idyal",
  date: "April 19, 2020",
  title: "SIGUIL",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "https://groupeidyal.com"
});
const getPlaceholderPost1 = () => ({
  imageSrc:
  "/images/siscofi-r.png",
  category: "Groupe idyal",
  date: "April 19, 2020",
  title: "SISCOFI",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "https://groupeidyal.com"
});
const getPlaceholderPost2 = () => ({
  imageSrc:
  "/images/ged.png",
  category: "Groupe idyal",
  date: "April 19, 2020",
  title: "GED",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "https://groupeidyal.com"
});
const getPlaceholderPost3 = () => ({
  imageSrc:
  "/images/od.png",
  category: "Groupe idyal",
  date: "April 19, 2020",
  title: "Idyal Drive",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "https://groupeidyal.com"
});
