import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw``}
  }
  ${NavToggle}.closed {
    ${tw`text-pink-700 hover:text-pink-700`}
  }
`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover mb-24`} // Ajout de la marge ici
  background-size: cover; /* Assure que l'image couvre toute la zone */
  background-position: center; /* Centre l'image d'arrière-plan */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.6);
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("/images/Man3.jpg");
    background-size: cover; /* Assure que l'ombre couvre toute la zone */
    background-position: center; /* Centre l'ombre */
  }
`;



const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`min-h-[800px] py-12 pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
//const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-2xl text-center lg:text-left sm:text-3xl lg:text-4xl xl:text-5xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2 mb-2`} /* Ajoute de l'espace entre chaque ligne */
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-pink-700 px-4 -mx-4 py-2 transition duration-300`}
  margin-left: 10px;

  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10 transition duration-300`}
  }

  &:hover {
    ${tw`text-white`}
    &::before {
      ${tw`bg-pink-700`}
    }
  }

 
`;


const Notification = tw.span`
  inline-block my-4 pl-3 py-1 text-pink-700 border-l-4 border-pink-700 
  font-medium text-sm bg-white
  ml-4 /* Décalage vers la droite */
`;

const PrimaryAction = styled(NavLink)`
  ${tw`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 
  bg-pink-700 text-white font-bold rounded shadow transition duration-300 
  transform hover:scale-105 focus:shadow-outline`}
  margin-left: 20px; /* Décalage vers la droite */

  /* Animation continue */
  animation: colorAnimation 3s infinite alternate; /* Animation de 3 secondes, infinie, avec alternance */

  @keyframes colorAnimation {
    0% {
      background-color: #B83280; /* Couleur initiale */
      color: #ffffff; /* Couleur du texte */
    }
    50% {
      background-color: #c02260; /* Couleur intermédiaire */
      color: #ffffff; /* Couleur du texte */
    }
    100% {
      background-color: #ffffff; /* Couleur finale */
      color: #B83280; /* Couleur du texte */
    }
  }
`;



const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Accueil</NavLink>
      <NavLink href="/realisation">Réalisations</NavLink>
      <NavLink href="/reference">Réferences</NavLink>
      <NavLink href="/formation">Formations</NavLink>
      <NavLink href="/a-propos">À propos</NavLink>
      <NavLink href="/contact">Contacts</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/contact">Nous Contacter</PrimaryLink>
    </NavLinks>
  ];

  return (
    <>
      <Container>
        <HeroContainer>
          <StyledHeader links={navLinks} />
          <TwoColumn>
          <LeftColumn>
  <Notification>Groupe IDYAL basé à Dakar, Sénégal.</Notification>
  <Heading>
  <SlantedBackground><span>L'innovation</span></SlantedBackground>
  <br />
  <SlantedBackground><span>au service</span></SlantedBackground>
  <br />
  <SlantedBackground><span>de vos IDÉES.</span></SlantedBackground>
</Heading>

  <div css={tw`h-24`} /> {/* Séparateur de hauteur */}
  <PrimaryAction href="/contact">Parlons de votre projet</PrimaryAction>
</LeftColumn>

            
          </TwoColumn>
        </HeroContainer>
       
      </Container>
    </>
  );
};
