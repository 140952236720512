import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container,  ContentWithPaddingXlA } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
// import Header from "components/headers/light.js";


const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Formation Devops" }) => {
  return (
    <AnimationRevealPage>
     
      <Container>
        <ContentWithPaddingXlA>
          
          <Text>
            {/* <p>Derniere mise a jour: Mardi 14 février 2023</p>

            <p>
            lorem ipsum dolor sit amet consectetur adipiscing elit
            </p>

            <p>
            lorem ipsum dolor sit amet consectetur adipiscing elit
            </p> */}

            <h1>LES FONCTIONNALITÉS PRINCIPALES</h1>
            
            <ul>
              <li>
                <p>
                Photos
                </p>
              </li>
              <li>
                <p>
                Vidéos
                </p>
              </li>
              <li>
                Audios
              </li>
              <li>
                Contacts
              </li>
              <li>
                Journal d’appel
              </li>{" "}
              <li>
                SMS
              </li>
              <li>
                Documents (Word, Excel, Powerpont, PDF)
              </li>
            </ul>  

          </Text>
        </ContentWithPaddingXlA>
      </Container>
     
    </AnimationRevealPage>
  );
};
