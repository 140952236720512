import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { motion, useTransform, useScroll } from "framer-motion";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

// Ajout du box-shadow au container avec des marges internes (padding) et des marges externes
const Container = styled.div`
  ${tw`relative my-40`}  // Ajout de margin top et bottom
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1); /* Box-shadow ajouté */
  border-radius: 20px; /* Bord arrondi pour un style plus moderne */
  padding: 2rem; /* Ajout de padding pour espacer le texte des bords */
  background-color: rgba(255, 228, 225, 0.8); /* Couleur de fond légère */
`;

const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-between items-center max-w-screen-xl w-full pb-5`}
  gap: 2rem; /* Ajustement de l'espace entre les colonnes */
`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = styled(Column)`
  ${tw`md:w-5/12 flex-shrink-0 relative flex justify-center items-center`}
  height: 100%; /* Assurer que l'image prend toute la hauteur */
`;

const TextColumn = styled(Column)`
  ${tw`md:w-7/12 mt-16 md:mt-0 flex flex-col justify-center`}
  padding: 1.5rem; /* Ajouter un espacement interne pour le texte */
`;

const MotionImage = styled(motion.img)`
  ${tw`rounded bg-contain bg-no-repeat bg-center h-full w-full object-cover`} /* Image pleine largeur et hauteur */
`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

// Directement utiliser motion.button pour animer le bouton
const MotionButton = motion(styled(PrimaryButtonBase)`
  ${tw`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`}
`);

export default ({
  subheading = "Groupe Idyal",
  heading = (
    <>
      Nous le faisons <wbr /> depuis <span tw="text-pink-700">2013</span>
    </>
  ),
  description = "Depuis 2013, nous innovons sans relâche, avec un service client exceptionnel et un fort engagement en matière de responsabilité sociale. Notre succès repose sur des années d'efforts dévoués pour offrir des solutions de qualité à nos clients.",
  primaryButtonText = "Voir plus",
  primaryButtonUrl = "/realisation",
  imageSrc = "/images/dev.svg", // Assurez-vous que ce chemin est correct ou remplacé
  textOnLeft = false
}) => {
  
  const { scrollYProgress } = useScroll();
  const scaleImage = useTransform(scrollYProgress, [0, 0.4], [0.6, 1.1]); // Animation scale pour l'image

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <MotionImage 
            src={imageSrc} 
            alt="Durabilité"
            style={{ scale: scaleImage }} 
          />
        </ImageColumn>
        <TextColumn>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>

            {/* Bouton animé avec hover et tap */}
            <MotionButton
              as="a"
              href={primaryButtonUrl}
              whileHover={{ scale: 1.1 }}   // Agrandit le bouton lors du survol
              whileTap={{ scale: 0.95 }}    // Réduit légèrement le bouton lors du clic
            >
              {primaryButtonText}
            </MotionButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
